<template>
  <div class="main-section">
    <div class="container">
      <div :class="['row', 'content', $activeLang]">
        <div
          :class="[
            'd-flex',
            'flex-column',
            'justify-content-start',
            'col-lg-5',
            'col-sm-12',
            'main-section-info',
            'text-center',
            $activeLang == 'ar' ? 'text-md-end' : 'text-md-start',
          ]"
        >
          <h6 class="sec-title intro">
            {{ $t("introDesc") }}
          </h6>
          <p
            :class="[
              'sec-desc',
              'text-center',
              $activeLang == 'ar' ? 'text-md-end' : 'text-md-start',
              'w-100',
            ]"
          >
            {{ $t("introLongDesc") }}
          </p>
          <div
            class="
              d-flex
              align-items-center
              justify-content-center
              mb-md-5 mb-4
            "
          >
            <router-link :to="{ name: 'ContactUs' }" class="btn-pri-dark mx-3">
              {{ $t("freeConsultation") }}
            </router-link>
            <button class="action-btn" @click="toggleVideoPlayer">
              <fa :icon="['fa', 'play']" />
            </button>
          </div>
          <div class="my-card mt-md-5 mt-0 mb-4">
            <div
              class="header d-flex justify-content-between align-items-center"
            >
              <ArrowIcons />
              <StarIcon />
            </div>
            <div class="body mt-3">
              {{ $t("mainQote") }}
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-sm-12 main-section-cover">
          <img
            src="../../assets/images/main-section-cover.png"
            alt="main section cover"
          />
          <div class="my-card">
            <div class="header d-flex align-items-center">
              <div class="icon me-md-2 ms-md-2 mx-2">
                <fa :icon="['fa', 'play']" />
              </div>
              <div class="info">
                <h6 class="title">{{ $t("businessAutomation") }}</h6>
                <p class="text">+1.2{{ $t("m") }} {{ $t("watching") }}</p>
              </div>
            </div>
            <div class="body">
              <div class="title">{{ $t("livePromotion") }}</div>
              <div class="row my-1 justify-content-between align-items-center">
                <div class="col-3">
                  <div class="icon">
                    <fa :icon="['fa', 'play']" />
                  </div>
                </div>
                <div class="col-9">
                  <p class="placeholder-glow">
                    <span class="placeholder col-11"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
              <div class="row my-1 justify-content-between align-items-center">
                <div class="col-3">
                  <div class="icon">
                    <fa :icon="['fa', 'play']" />
                  </div>
                </div>
                <div class="col-9">
                  <p class="placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="my-card clients">
            <div
              class="header d-flex align-items-center justify-content-between"
            >
              <div class="info">
                <h6 class="title">{{ $t("clients") }}</h6>
              </div>
              <div
                class="
                  icon
                  d-flex
                  justify-content-end
                  align-items-center
                  flex-column
                "
              >
                <fa :icon="['fa', 'circle']" />
                <fa :icon="['fa', 'circle']" />
              </div>
            </div>
            <div class="body">
              <div class="row my-1 justify-content-between align-items-center">
                <div class="col-3">
                  <div class="icon">
                    <img
                      src="../../assets/images/placeholder.png"
                      alt="Placeholder Clinent"
                    />
                  </div>
                </div>
                <div class="col-9">
                  <p class="placeholder-glow">
                    <span class="placeholder col-8"></span>
                    <span class="placeholder col-10"></span>
                  </p>
                </div>
              </div>
              <div class="row my-1 justify-content-between align-items-center">
                <div class="col-3">
                  <div class="icon"></div>
                </div>
                <div class="col-9">
                  <p class="placeholder-glow">
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-9"></span>
                  </p>
                </div>
              </div>
              <div class="row my-1 justify-content-between align-items-center">
                <div class="col-3">
                  <div class="icon"></div>
                </div>
                <div class="col-9">
                  <p class="placeholder-glow">
                    <span class="placeholder col-12"></span>
                    <span class="placeholder col-3"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="my-card clients updates">
            <div
              class="header d-flex align-items-center justify-content-between"
            >
              <div class="info">
                <h6 class="title">{{ $t("liveUpdate") }}</h6>
              </div>
              <div
                class="
                  icon
                  d-flex
                  justify-content-end
                  align-items-center
                  flex-column
                "
              >
                <fa :icon="['fa', 'circle']" />
                <fa :icon="['fa', 'circle']" />
              </div>
            </div>
            <div class="body">
              <div class="row my-1 justify-content-between align-items-center">
                <div class="col-6">
                  <div class="count">37%</div>
                  <div class="name">{{ $t("growthRate") }}</div>
                </div>
                <div class="col-6">
                  <div class="chart">
                    <img
                      src="../../assets/images/line.png"
                      alt="Placeholder "
                    />
                  </div>
                </div>
              </div>
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <div class="count">1.65M</div>
                  <div class="name">{{ $t("newUsers") }}</div>
                </div>
                <div class="col-6">
                  <div class="chart">
                    <img
                      src="../../assets/images/line-2.png"
                      alt="Placeholder "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <veido-player @close="toggleVideoPlayer" v-if="playVideo" />
    </transition>
  </div>
</template>

<script>
import ArrowIcons from "../../assets/images/main-section-card.svg";
import StarIcon from "../../assets/images/star.svg";
import VeidoPlayer from "../misc/VeidoPlayer.vue";
export default {
  components: {
    ArrowIcons,
    StarIcon,
    VeidoPlayer,
  },
  data() {
    return {
      playVideo: false,
    };
  },
  methods: {
    toggleVideoPlayer() {
      this.playVideo = !this.playVideo;
    },
  },
  mounted() {
    const card = document.querySelector(".my-card");
    if (card) {
      window.addEventListener("scroll", () => {
        if (parseInt(card.getBoundingClientRect().top) <= 80) {
          card.style.zIndex = "2";
        } else {
          card.style.zIndex = "7";
        }
      });
    }
  },
};
</script>

<style>
</style>