<template>
  <div class="social">
    <ul class="d-flex align-items-center">
      <li :title="soLink.name" v-for="(soLink, i) in socialLinks" :key="i">
        <a :href="soLink.link" class="link" target="_blnak"
          ><fa :icon="soLink.icon"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      socialLinks: [
        {
          name: "Facebook Account",
          link: "https://www.facebook.com/digitaleka/",
          icon: ["fab", "facebook"],
        },
        // {
        //   name: "Instagram Account",
        //   link: "https://www.instagram.com",
        //   icon: ["fab", "instagram"],
        // },
        // {
        //   name: "Twitter Account",
        //   link: "https://www.twitter.com",
        //   icon: ["fab", "twitter"],
        // },
        // {
        //   name: "LinkedIn Account",
        //   link: "https://www.linkedind.com",
        //   icon: ["fab", "linkedin"],
        // },
      ],
    };
  },
};
</script>

<style>
</style>