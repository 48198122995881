<template>
  <div
    class="service-card my-card"
    @mouseenter="expandData"
    @mouseleave="dispandData"
  >
    <!-- @mouseover="openService(service)" -->
    <div class="position-relative">
      <div class="icon">
        <EditIcon v-if="service.logo == 'edit'" />
        <cloudIcon v-if="service.logo == 'cloud'" />
        <cyberIcon v-if="service.logo == 'cyber'" />
        <switchIcon v-if="service.logo == 'switch'" />
        <dataIcon v-if="service.logo == 'data'" />
        <webIcon v-if="service.logo == 'web'" />
        <mobileIcon v-if="service.logo == 'mobile'" />
        <laptopIcon v-if="service.logo == 'laptop'" />
      </div>
      <div :class="['title', $activeLang == 'ar' ? 'text-end' : 'text-start']">
        {{ service.title }}
      </div>
      <div :class="['desc', $activeLang == 'ar' ? 'text-end' : 'text-start']">
        {{
          `${service.desc.slice(0, sliceVal)} ${
            service.desc.length >= sliceVal ? dots : ""
          }`
        }}
      </div>
    </div>
  </div>
</template>

<script>
import EditIcon from "../../assets/images/edit.svg";
import cloudIcon from "../../assets/images/clud.svg";
import cyberIcon from "../../assets/images/cyber.svg";
import dataIcon from "../../assets/images/data.svg";
import laptopIcon from "../../assets/images/laptop.svg";
import switchIcon from "../../assets/images/switch.svg";
import webIcon from "../../assets/images/web.svg";
import mobileIcon from "../../assets/images/mobile.svg";
export default {
  components: {
    EditIcon,
    cloudIcon,
    dataIcon,
    cyberIcon,
    switchIcon,
    webIcon,
    mobileIcon,
    laptopIcon,
  },
  data() {
    return {
      sliceVal: 70,
      dots: "...",
    };
  },
  props: ["service"],
  methods: {
    openService(ser) {
      this.$emit("openService", ser);
    },
    expandData() {
      this.sliceVal = 1000;
      this.dots = "";
    },
    dispandData() {
      this.sliceVal = 70;
      this.dots = "...";
    },
  },
};
</script>

<style>
</style>