<template>
  <div>
    <div class="blob">
      <blob />
    </div>
    <AppHeader :scrollValue="scrollValue" />
    <div id="container">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
      >
        <router-view v-slot="Component">
          <component :is="Component"></component>
        </router-view>
      </transition>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "./components/Navigation/AppHeader.vue";
import AppFooter from "./components/Navigation/AppFooter.vue";
import blob from "./assets/images/blob.svg";
export default {
  components: {
    AppHeader,
    AppFooter,
    blob,
  },
  data() {
    return {
      scrollValue: 0,
    };
  },
  name: "App",
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollValue =
        (window.scrollY * 100) / (document.body.clientHeight - 850);
    });
    document.body.classList.add(this.$activeLang || this.osLang);
  },
};
</script>

<style lang="scss">
@import "./assets/scss/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
.animate__animated.fadeIn {
  --animation-duration: 0.1s !important;
}
.animate__animated.fadeInUp {
  --animation-duration: 0.1s !important;
}
.animate__animated.fadeOutDown {
  --animation-duration: 0.1s !important;
}
</style>
